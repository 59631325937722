import revive_payload_client_hFpxt_QQNY4d90tLLH8AP4TSC_C6XdAqPJlnidhp7tQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__f4610ba7490d0330676fbb331e6e20c3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OO1dlQSuRkBMtTHkiuJrQzQuypQsUl5GYNI9J84xgzA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__f4610ba7490d0330676fbb331e6e20c3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_lbfygBf7orMYGS8cvlrmfvlq1caNyb1y_2aqIrutiXc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__f4610ba7490d0330676fbb331e6e20c3/node_modules/nuxt/dist/app/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import cloudflare_NHdN6DxyC1D5tu_68LqxAtJsGFs7w7QfELBriQFzHtc from "/opt/buildhome/repo/app/plugins/cloudflare.ts";
import payload_client_VTsmcFp0QSSrB0gcOVk37WFCxWlo_J5y81LfqcMPSBw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__f4610ba7490d0330676fbb331e6e20c3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_wmSrJa9IroIBn9wkpumnXLbiScaqGzbtopoSwg0eyj0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__f4610ba7490d0330676fbb331e6e20c3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bIB_9kuF3QSP2INoJINePWHbY1Dj4JyoFddIn5_gVkc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__f4610ba7490d0330676fbb331e6e20c3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_7GQULh9LrMphPdXZC14h_8EdRa2SGWTitO2xXz0Sks0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__f4610ba7490d0330676fbb331e6e20c3/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_072eLhtR0XehTjrfFQ6YTnIz90A2wDSl7N6JfwkSA5w from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__f4610ba7490d0330676fbb331e6e20c3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import plugin_client_0DSVlk_DWJIsXiY4Q2rzCCZ9XNjn36aQ0XNJc69R_bc from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_6Z4vW7S9aX_q2svWbGBc_X2b5QbQdkNmvzr_3kqqCd0 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
import auth_H8gVM_hLMR4kH70PZQlWNqJIB9Rdr0rL0wFPPsoYoYA from "/opt/buildhome/repo/app/plugins/auth.ts";
import cal_xt2IFNa6DRSO_UE6iaCnwZzMEiGCRsZ0Qo4enyBEwvM from "/opt/buildhome/repo/app/plugins/cal.ts";
import idle_client_NmfLz10EqSUel0I4HUNcMS4u2YnnbN9zN6L_w7xhXtM from "/opt/buildhome/repo/app/plugins/idle.client.ts";
import plugin_auto_pageviews_client_jObAEHGbMiFxvkHYEDQUtr3ztxD1c5DoRbsB1cWdRVs from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_hFpxt_QQNY4d90tLLH8AP4TSC_C6XdAqPJlnidhp7tQ,
  unhead_OO1dlQSuRkBMtTHkiuJrQzQuypQsUl5GYNI9J84xgzA,
  router_lbfygBf7orMYGS8cvlrmfvlq1caNyb1y_2aqIrutiXc,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  cloudflare_NHdN6DxyC1D5tu_68LqxAtJsGFs7w7QfELBriQFzHtc,
  payload_client_VTsmcFp0QSSrB0gcOVk37WFCxWlo_J5y81LfqcMPSBw,
  navigation_repaint_client_wmSrJa9IroIBn9wkpumnXLbiScaqGzbtopoSwg0eyj0,
  check_outdated_build_client_bIB_9kuF3QSP2INoJINePWHbY1Dj4JyoFddIn5_gVkc,
  view_transitions_client_7GQULh9LrMphPdXZC14h_8EdRa2SGWTitO2xXz0Sks0,
  chunk_reload_client_072eLhtR0XehTjrfFQ6YTnIz90A2wDSl7N6JfwkSA5w,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  plugin_client_0DSVlk_DWJIsXiY4Q2rzCCZ9XNjn36aQ0XNJc69R_bc,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  unocss_6Z4vW7S9aX_q2svWbGBc_X2b5QbQdkNmvzr_3kqqCd0,
  auth_H8gVM_hLMR4kH70PZQlWNqJIB9Rdr0rL0wFPPsoYoYA,
  cal_xt2IFNa6DRSO_UE6iaCnwZzMEiGCRsZ0Qo4enyBEwvM,
  idle_client_NmfLz10EqSUel0I4HUNcMS4u2YnnbN9zN6L_w7xhXtM,
  plugin_auto_pageviews_client_jObAEHGbMiFxvkHYEDQUtr3ztxD1c5DoRbsB1cWdRVs
]